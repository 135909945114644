import React from "react";
import "./style.css";

export const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="div">
        <div className="overlap">
          <img className="main-bg" alt="Main bg" src="https://c.animaapp.com/LwSFHiby/img/main-bg.png" />
          <footer className="footer">
            <div className="subfooter">
              <div className="overlap-group">
                <div className="cookies-policy-terms">
                  Cookies&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Policy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Terms
                  of Service
                </div>
                <p className="text-wrapper">©2024 - All rights reserved</p>
                <p className="p">Crowdmoovi SRL - 0123456789 - Torino - Cap. 100.000€ i.v</p>
              </div>
            </div>
            <div className="group">
              <div className="rimani-aggiornato">RIMANI AGGIORNATO</div>
              <img className="line" alt="Line" src="https://c.animaapp.com/LwSFHiby/img/line-5.svg" />
              <img className="linkedin" alt="Linkedin" src="https://c.animaapp.com/LwSFHiby/img/linkedin-1.svg" />
              <img className="facebook" alt="Facebook" src="https://c.animaapp.com/LwSFHiby/img/facebook-1.svg" />
              <img className="instagram" alt="Instagram" src="https://c.animaapp.com/LwSFHiby/img/instagram-1.svg" />
            </div>
            <div className="group-2">
              <div className="scopri-di-pi">SCOPRI DI PIÙ</div>
              <div className="team">TEAM</div>
              <div className="white-paper">WHITE PAPER</div>
              <div className="roadmap">ROADMAP</div>
              <img className="img" alt="Line" src="https://c.animaapp.com/LwSFHiby/img/line-5.svg" />
            </div>
            <img
              className="crowdmoovi-logo"
              alt="Crowdmoovi logo"
              src="https://c.animaapp.com/LwSFHiby/img/crowdmoovi-logo-1.svg"
            />
          </footer>
          <div className="CLUB">
            <div className="invest">
              <div className="overlap-group-2">
                <div className="text-wrapper-2">latua@email.com</div>
                <img className="vector" alt="Vector" src="https://c.animaapp.com/LwSFHiby/img/vector-7.svg" />
              </div>
            </div>
            <p className="text-wrapper-3">
              Rimani aggiornato per avere l’esclusività il giorno del lancio e per ricevere aggiornamenti per le future
              uscite e novità!
            </p>
            <div className="overlap-2">
              <div className="text-wrapper-4">EXCLUSIVE</div>
              <div className="text-wrapper-5">EXCLUSIVE CLUB</div>
              <div className="text-wrapper-6">JOIN THE</div>
            </div>
          </div>
          <div className="OUR-NFT">
            <div className="overlap-3">
              <div className="overlap-group-wrapper">
                <div className="overlap-group-3">
                  <div className="text-wrapper-7">INVESTI SUBITO</div>
                  <img className="vector-2" alt="Vector" src="https://c.animaapp.com/LwSFHiby/img/vector-7-2.svg" />
                </div>
              </div>
              <div className="element">
                <div className="overlap-4">
                  <div className="rectangle" />
                  <div className="div-wrapper">
                    <div className="overlap-group-4">
                      <div className="rectangle-2" />
                      <div className="text-wrapper-8">Royalties</div>
                      <div className="text-wrapper-9">NO</div>
                    </div>
                  </div>
                  <div className="element-2">
                    <p className="text-wrapper-10">Commissioni per l&#39;artista sul totale dei fondi raccolti</p>
                    <div className="text-wrapper-11">8%</div>
                  </div>
                  <div className="element-3">
                    <p className="text-wrapper-10">Commissioni di elaborazione pagamento su ogni contributo</p>
                    <div className="text-wrapper-11">5%</div>
                  </div>
                  <div className="fuori-da-CROWDMOOVI">
                    Fuori da
                    <br />
                    CROWDMOOVI
                  </div>
                </div>
              </div>
              <div className="overlap-wrapper">
                <div className="overlap-5">
                  <div className="element-2">
                    <p className="text-wrapper-12">Commissioni per l&#39;artista sul totale dei fondi raccolti</p>
                    <div className="text-wrapper-13">3%</div>
                  </div>
                  <div className="element-3">
                    <p className="text-wrapper-12">
                      Commissioni sull&#39;acquisto di NFTs degli artisti sul marketplace
                    </p>
                    <div className="text-wrapper-13">2%</div>
                  </div>
                  <div className="senza-GENESIS-NFT">
                    Senza
                    <br />
                    GENESIS NFT
                  </div>
                </div>
              </div>
              <div className="overlap-6">
                <div className="element-4">
                  <div className="overlap-4">
                    <div className="rectangle-3" />
                    <div className="div-wrapper">
                      <div className="overlap-group-4">
                        <div className="rectangle-4" />
                        <p className="accesso-anticipato-a">
                          Accesso anticipato a tutte
                          le future collezioni NFT
                        </p>
                        <div className="text-wrapper-14">+</div>
                      </div>
                    </div>
                    <div className="element-2">
                      <p className="text-wrapper-12">Commissioni per l&#39;artista sul totale dei fondi raccolti</p>
                      <div className="text-wrapper-15">1%</div>
                    </div>
                    <div className="element-3">
                      <p className="text-wrapper-12">
                        Commissioni sull&#39;acquisto di NFTs degli artisti sul marketplace
                      </p>
                      <div className="text-wrapper-15">0.8%</div>
                    </div>
                    <p className="text-wrapper-16">Con almeno 1 GENESIS NFT</p>
                  </div>
                </div>
                <p className="CON-GENESIS-CONVIENE">
                  <span className="span">CON</span>
                  <span className="text-wrapper-17"> GENESIS </span>
                  <span className="text-wrapper-18">CONVIENE</span>
                </p>
                <div className="group-3">
                  <img className="line-2" alt="Line" src="https://c.animaapp.com/LwSFHiby/img/line-1.svg" />
                  <img className="line-3" alt="Line" src="https://c.animaapp.com/LwSFHiby/img/line-2.svg" />
                  <img className="line-4" alt="Line" src="https://c.animaapp.com/LwSFHiby/img/line-3.svg" />
                  <img className="line-5" alt="Line" src="https://c.animaapp.com/LwSFHiby/img/line-4.svg" />
                </div>
              </div>
            </div>
            <div className="overlap-7">
              <img className="nft" alt="Nft" src="https://c.animaapp.com/LwSFHiby/img/nft-1.png" />
              <div className="buy">
                <div className="overlap-8">
                  <div className="text-wrapper-19">SCOPRI GENESIS</div>
                  <img className="vector-3" alt="Vector" src="https://c.animaapp.com/LwSFHiby/img/vector-7-2.svg" />
                </div>
              </div>
              <div className="text-wrapper-20">GENESIS</div>
              <div className="text-wrapper-21">NFT COLLECTION</div>
              <div className="text-wrapper-22">OUR</div>
            </div>
          </div>
          <div className="HOW">
            <div className="overlap-9">
              <div className="finance-your">
                <img
                  className="firefly-ragazzo-e"
                  alt="Firefly ragazzo e"
                  src="https://c.animaapp.com/LwSFHiby/img/firefly-ragazzo-e-ragazza-di-25-anni-che-vincono-soldi--sfondo-n.png"
                />
                <p className="realizza-i-tuoi">
                  Realizza i tuoi sogni! Trova il supporto che ti serve per portare avanti le tue idee. Con la nostra
                  piattaforma, puoi raccogliere fondi, attrarre investitori e trasformare i tuoi progetti in realtà.
                  <br />
                  Il futuro dei tuoi progetti inizia qui!
                </p>
                <p className="element-finanza-i-tuoi">
                  <span className="text-wrapper-23">4.</span>
                  <span className="text-wrapper-24">&nbsp;</span>
                  <span className="text-wrapper-25">Finanza i tuoi progetti</span>
                </p>
                <div className="rectangle-5" />
              </div>
              <div className="launch-your-campaign">
                <p className="hai-un-idea-ora-di">
                  Hai un&#39;idea? È ora di condividerla con il mondo! Lancia la tua campagna in pochi passaggi e
                  coinvolgi il tuo pubblico. Raccogli fondi, crea consapevolezza e dai vita al tuo progetto. Inizia oggi
                  e fai la differenza!
                </p>
                <p className="element-lancia-la-tua">
                  <span className="text-wrapper-23">3.</span>
                  <span className="text-wrapper-24">&nbsp;</span>
                  <span className="text-wrapper-25">Lancia la tua campagna</span>
                </p>
                <div className="rectangle-6" />
                <img className="img-2" alt="Element" src="https://c.animaapp.com/LwSFHiby/img/3-1.png" />
              </div>
              <div className="create-your-nft">
                <img
                  className="create-nft"
                  alt="Create nft"
                  src="https://c.animaapp.com/LwSFHiby/img/create-nft-1.png"
                />
                <p className="trasforma-la-tua">
                  Trasforma la tua creatività in un&#39;opera digitale unica! Crea il tuo NFT in pochi clic e porta la
                  tua arte nel mondo del collezionismo digitale. Scegli, personalizza e conia il tuo NFT per entrare
                  nella rivoluzione blockchain
                </p>
                <p className="element-crea-il-tuo-NFT">
                  <span className="text-wrapper-26">2.</span>
                  <span className="text-wrapper-24">&nbsp;</span>
                  <span className="text-wrapper-25">Crea il tuo NFT</span>
                </p>
                <div className="rectangle-7" />
              </div>
              <div className="create-your-profile">
                <img
                  className="img-2"
                  alt="Create profile"
                  src="https://c.animaapp.com/LwSFHiby/img/create-profile-1.png"
                />
                <p className="text-wrapper-27">
                  Personalizza la tua esperienza! Crea il tuo profilo in pochi semplici passi e accedi a funzionalità
                  esclusive. Condividi le tue passioni, connettiti con la nostra community e scopri contenuti su misura
                  per te. Il tuo viaggio inizia qui!
                </p>
                <p className="element-crea-il-tuo">
                  <span className="text-wrapper-26">1.</span>
                  <span className="text-wrapper-24">&nbsp;</span>
                  <span className="text-wrapper-25">Crea il tuo profilo</span>
                </p>
                <div className="rectangle-8" />
              </div>
              <div className="text-wrapper-20">HOW?</div>
              <div className="funziona-crowdmoovi">FUNZIONA CROWDMOOVI</div>
              <div className="text-wrapper-28">COME</div>
              <img className="vector-4" alt="Vector" src="https://c.animaapp.com/LwSFHiby/img/vector-9.svg" />
            </div>
          </div>
          <div className="WHY">
            <div className="overlap-10">
              <div className="element-5">
                <p className="si-applica-una">
                  <span className="text-wrapper-29">
                    Si applica una commissione sui fondi raccolti solamente se il progetto raggiunge l&#39;obiettivo di
                    finanziamento. Questa commissione{" "}
                  </span>
                  <span className="text-wrapper-30">(5-8% su piattaforme esterne)</span>
                  <span className="text-wrapper-29">
                    , su Crowdmoovi parte da un 3% per arrivare all&#39;1% se si possiede un &#34;Genesis NFT”
                  </span>
                </p>
                <div className="risparmio-per-gli">
                  Risparmio
                  <br />
                  per gli Artisti
                </div>
                <img
                  className="firefly-icona-con"
                  alt="Firefly icona con"
                  src="https://c.animaapp.com/LwSFHiby/img/firefly-icona-con-una-calcolatrice-e-delle-banconote--sfondo-ner@2x.png"
                />
              </div>
              <div className="overlap-11">
                <div className="element-6">
                  <p className="text-wrapper-31">
                    Crea la tua campagna e guadagna royalties fino al 10% su ogni rivendita delle nft cards create da
                    te. Utilizza le tue cards per offrire imperdibili vantaggi e creare una solida community di persone
                    appassionate al progetto
                  </p>
                  <div className="royalties-community">
                    Royalties
                    <br />
                    &amp; Community
                  </div>
                  <img
                    className="firefly-create-a"
                    alt="Firefly create a"
                    src="https://c.animaapp.com/LwSFHiby/img/firefly-create-a-circular-icon-divided-into-two-halves--the-uppe@2x.png"
                  />
                </div>
                <div className="text-wrapper-20">WHY?</div>
                <div className="text-wrapper-21">SCEGLIERE CROWDMOOVI</div>
                <div className="text-wrapper-22">PERCHÈ</div>
              </div>
              <div className="element-7">
                <p className="grazie-alle-nft">
                  Grazie alle nft cards, gli artisti possono ricompensare i sostenitori con un&#39;infinità di vantaggi
                  fisici e/o digitali. Una card rappresenta una frazione del diritto d&#39;autore del film, oltre ai
                  vantaggi già visionati, e può essere scambiata o rivenduta, a differenza dei vantaggi delle altre
                  piattaforme di crowdfunding.
                </p>
                <div className="ricompense-per-i">
                  + Ricompense
                  <br />
                  per i sostenitori
                </div>
                <img
                  className="firefly-icona-con-2"
                  alt="Firefly icona con"
                  src="https://c.animaapp.com/LwSFHiby/img/firefly-icona-con-una-calcolatrice-e-delle-banconote--sfondo-ner-1@2x.png"
                />
              </div>
            </div>
            <div className="mission">
              <div className="overlap-group-5">
                <div className="group-4">
                  <div className="rectangle-9" />
                  <div className="rectangle-10" />
                </div>
                <div className="group-5">
                  <div className="rectangle-9" />
                  <div className="rectangle-11" />
                </div>
                <div className="la-nostra-mission">LA NOSTRA MISSION</div>
                <p className="il-nostro-obiettivo">
                  Il nostro obiettivo è aiutare gli artisti cinematografici a finanziare i propri progetti in maniera
                  innovativa, più efficiente, creativa, coinvolgente e meno costosa.
                  <br />
                  <br />
                  Riteniamo fondamentale portare nel mondo del cinema un più ampio senso di comunità e libertà
                  artistica, incentivando gli artisti ad esprimere le proprie idee con imprenditoria creativa senza
                  barriere.
                </p>
              </div>
            </div>
          </div>
          <div className="hero">
            <div className="overlap-12">
              <img className="png-NB" alt="Png NB" src="https://c.animaapp.com/LwSFHiby/img/png-nb-1.png" />
              <div className="scopri-di-pi-2">
                <div className="SCOPRI-DI-pi">SCOPRI DI PIÙ</div>
                <div className="overlap-group-6">
                  <div className="rectangle-12" />
                  <img className="vector-5" alt="Vector" src="https://c.animaapp.com/LwSFHiby/img/vector-7-3.svg" />
                </div>
                <div className="rectangle-13" />
              </div>
              <p className="first-NFT-DRIVEN">
                <span className="text-wrapper-29">
                  First NFT-DRIVEN crowdfunding platform
                  <br />
                </span>
                <span className="text-wrapper-32">made for cinema artists, by cinema artists</span>
              </p>
              <div className="rectangle-14" />
              <div className="text-wrapper-33">CROWDFUNDING</div>
              <div className="text-wrapper-34">NFT REWARD</div>
            </div>
          </div>
          <div className="lines-on-top">
            <div className="overlap-13">
              <div className="rectangle-15" />
              <div className="rectangle-16" />
              <div className="rectangle-17" />
            </div>
          </div>
        </div>
        <header className="header">
          <div className="overlap-14">
            <div className="overlap-15">
              <div className="rectangle-18" />
              <img
                className="crowdmoovi-logo-2"
                alt="Crowdmoovi logo"
                src="https://c.animaapp.com/LwSFHiby/img/crowdmoovi-logo-1-1.svg"
              />
            </div>
            <div className="overlap-16">
              <div className="rectangle-19" />
              <div className="marketplace-button">
                <div className="marketplace-wrapper">
                  <div className="marketplace">MARKETPLACE</div>
                </div>
              </div>
            </div>
            <div className="overlap-17">
              <div className="rectangle-20" />
              <div className="come-funziona">COME FUNZIONA</div>
              <div className="perch">PERCHÈ</div>
            </div>
            <div className="join-the-club">JOIN THE CLUB</div>
            <div className="genesis-nft">GENESIS NFT</div>
          </div>
        </header>
      </div>
    </div>
  );
};
